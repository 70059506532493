.cp-contact-page {
    font-family: 'Inter', sans-serif;
    background-color: transparent;
    color: #ffffff;
    min-height: 100vh;
    padding: 80px 0;
  }
  
  .cp-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .cp-title {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .cp-subtitle {
    font-size: 18px;
    color: #999999;
    margin-bottom: 40px;
    max-width: 600px;
  }
  
  .cp-content {
    display: flex;
    justify-content: space-between;
    gap: 60px;
  }
  
  .cp-info {
    flex: 1;
  }
  
  .cp-info-section {
    margin-bottom: 40px;
  }
  
  .cp-info-section h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #ffffff;
  }
  
  .cp-info-section p {
    font-size: 16px;
    color: #999999;
    margin-bottom: 5px;
  }
  
  .cp-form {
    flex: 2;
  }
  
  .cp-form-group {
    margin-bottom: 20px;
  }
  
  .cp-form-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    color: #ffffff;
  }
  
  .cp-form-group input,
  .cp-form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #333333;
    border: none;
    border-radius: 4px;
    color: #ffffff;
  }
  
  .cp-form-group textarea {
    height: 150px;
    resize: vertical;
  }
  
  .cp-submit-button {
    background-color: #ffffff;
    color: #111111;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cp-submit-button:hover {
    background-color: #e0e0e0;
  }
  
  @media (max-width: 768px) {
    .cp-content {
      flex-direction: column;
    }
  
    .cp-info,
    .cp-form {
      flex: 1;
    }
  }

  .cp-discord {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .cp-discord-icon {
    font-size: 24px;
    margin-right: 10px;
    color: #ffffff; /* Discord brand color */
  }

  .cp-discord a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
  }

  .cp-discord a:hover {
    text-decoration: underline;
  }
