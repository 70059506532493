@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Inter', sans-serif;
  background-color: #000;
  color: #fff;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: radial-gradient(circle, rgba(29,29,29,1) 14%, rgba(0,0,0,1) 77%);
  color: #ffffff;
  position: relative;
  z-index: 1;
}

.navigation {
  background-color: transparent;
  padding: 20px 44px;
}

.scrollable-content {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ffffff #131313;
}

.scrollable-content::-webkit-scrollbar {
  width: 8px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: #131313;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #232448;
  border-radius: 4px;
}

.app-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 44px;
  
}

/* HomePage styles */
.home-page {
  max-width: 1200px;
  margin: 0 auto;
}

.hero {
  text-align: center;
  padding: 80px 20px;
  position: relative;
  overflow: hidden;
}

.hero-content {
  position: relative;
  z-index: 1;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero p {
  font-size: 20px;
  margin-bottom: 40px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.primary-button, .secondary-button, .cta-button {
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primary-button {
  background-color: #ffffff;
  color: #04080C;
  border: none;
}

.secondary-button {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.review-avatars {
  display: flex;
}

.review-avatars img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #04080C;
  margin-left: -10px;
}

.review-text {
  text-align: left;
}

.stars {
  color: #FFD700;
  font-size: 18px;
}

/* Feature Section */
.features {
  padding: 80px 20px;
  text-align: center;
}

.features h2 {
  font-size: 36px;
  margin-bottom: 40px;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
}

.feature-item {
  text-align: center;
}

.feature-icon {
  font-size: 48px;
  margin-bottom: 20px;
}

.feature-item h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 16px;
  color: #ffffff;
}

/* Demo Section */
.demo {
  padding: 80px 20px;
  text-align: center;
  background-color: transparent;
}

.demo h2 {
  font-size: 36px;
  margin-bottom: 40px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Pricing Section */
.pricing {
  padding: 80px 20px;
  text-align: center;
}

.pricing h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.pricing > p {
  font-size: 18px;
  margin-bottom: 40px;
  color: #ffffff;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  margin-top: 60px;
}

.pricing-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  transition: all 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.pricing-card h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.pricing-card p {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 40px;
}

.price {
  margin-bottom: 40px;
}

.price-value {
  font-size: 48px;
  font-weight: bold;
}

.price-frequency {
  font-size: 18px;
  color: #ffffff;
}

.feature-list {
  list-style-type: none;
  padding: 0;
  margin-top: 40px;
  text-align: left;
}

.feature-list li {
  margin-bottom: 10px;
}

/* Testimonial Section */
.testimonials {
  padding: 80px 20px;
  background-color: transparent;
  color: #fff;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
}

.testimonial-item blockquote {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.testimonial-item footer {
  font-size: 16px;
}

.testimonial-item cite {
  font-weight: bold;
}

/* CTA Section */
.cta {
  padding: 80px 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.05);
}

.cta h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.cta p {
  font-size: 18px;
  margin-bottom: 40px;
  color: #ffffff;
}

/* Footer styles */
.footer {
  background-color: transparent;
  color: #ffffff;
  padding: 80px 44px 0;
  font-family: 'Inter', sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  border-top: 1px solid #333;
  padding-top: 40px;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.footer-section h3,
.footer-header {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #ffffff;
  height: 24px;
  display: flex;
  align-items: center;
}

.footer-logo {
  height: 24px;
  width: auto;
}

.footer-section p {
  font-size: 14px;
  line-height: 1.5;
  color: #a7a7a7;
  max-width: 75%;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #a7a7a7;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #ffffff;
}

.footer-bottom {
  max-width: 1200px;
  margin: 0 auto;
  border-top: 1px solid #333;
  margin-top: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-bottom p {
  font-size: 14px;
  color: #a7a7a7;
}

.social-icons {
  display: flex;
  gap: 20px;
}

.social-icons a {
  color: #a7a7a7;
  font-size: 20px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #ffffff;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 30px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

/* Responsive design */
@media (max-width: 768px) {
  h1 {
    font-size: 48px;
  }

  .hero p {
    font-size: 18px;
  }

  .cta-buttons {
    flex-direction: column;
  }

  .feature-grid, .pricing-grid {
    grid-template-columns: 1fr;
  }
}

/* Loading spinner */
.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: radial-gradient(circle, rgba(29,29,29,1) 14%, rgba(0,0,0,1) 77%);
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #020202;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error {
  background-color: #ffdddd;
  color: #ff0000;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

/* Plasma Background */
.plasma-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.gradient {
  position: absolute;
  border-radius: 100%;
  opacity: 0.6;
  mix-blend-mode: screen;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.1, 0, 0.9, 1);
}

.gradient-1 {
  background: rgb(255, 247, 0);
  width: 700px;
  height: 700px;
  animation-duration: 11s;
  left: 60%;
  top: 40%;
  animation-name: animation-gradient-1;
}

.gradient-2 {
  background: rgb(255, 0, 38);
  width: 600px;
  height: 600px;
  animation-duration: 11s;
  left: 40%;
  top: 60%;
  animation-name: animation-gradient-2;
}

.gradient-3 {
  background: rgb(0, 0, 255);
  width: 500px;
  height: 500px;
  animation-duration: 11s;
  left: 50%;
  top: 50%;
  animation-name: animation-gradient-3;
}

@keyframes animation-gradient-1 {
  0% {
    transform: translateY(-50%) translateX(-50%) rotate(-20deg) translateX(20%);
  }
  25% {
    transform: translateY(-50%) translateX(-50%) skew(-15deg, -15deg)
      rotate(80deg) translateX(30%);
  }
  50% {
    transform: translateY(-50%) translateX(-50%) rotate(180deg) translateX(25%);
  }
  75% {
    transform: translateY(-50%) translateX(-50%) skew(15deg, 15deg)
      rotate(240deg) translateX(15%);
  }
  100% {
    transform: translateY(-50%) translateX(-50%) rotate(340deg) translateX(20%);
  }
}

@keyframes animation-gradient-2 {
  0% {
    transform: translateY(-50%) translateX(-50%) rotate(40deg) translateX(-20%);
  }
  25% {
    transform: translateY(-50%) translateX(-50%) skew(15deg, 15deg)
      rotate(110deg) translateX(-5%);
  }
  50% {
    transform: translateY(-50%) translateX(-50%) rotate(210deg) translateX(-35%);
  }
  75% {
    transform: translateY(-50%) translateX(-50%) skew(-15deg, -15deg)
      rotate(300deg) translateX(-10%);
  }
  100% {
    transform: translateY(-50%) translateX(-50%) rotate(400deg) translateX(-20%);
  }
}

@keyframes animation-gradient-3 {
  0% {
    transform: translateY(-50%) translateX(-50%) translateX(-15%)
      translateY(10%);
  }
  20% {
    transform: translateY(-50%) translateX(-50%) translateX(20%)
      translateY(-30%);
  }
  40% {
    transform: translateY(-50%) translateX(-50%) translateX(-25%)
      translateY(-15%);
  }
  60% {
    transform: translateY(-50%) translateX(-50%) translateX(30%) translateY(20%);
  }
  80% {
    transform: translateY(-50%) translateX(-50%) translateX(5%) translateY(35%);
  }
  100% {
    transform: translateY(-50%) translateX(-50%) translateX(-15%)
      translateY(10%);
  }
}

/* Ensure the app content is above the background */
.app-wrapper {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Update the existing styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Inter', sans-serif;
  background-color: #000;
  color: #fff;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Add these new styles */
.footer-header {
  height: 24px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
}

.footer-logo {
  height: 100%;
  width: auto;
  padding-top: 15px;
}

/* Update the existing footer-section styles */
.footer-section {
  flex: 1;
  min-width: 200px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.footer-section h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #ffffff;
  height: 24px;
  display: flex;
  align-items: flex-start;
}

/* ... rest of your existing styles ... */