@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.db-dashboard-container {
  font-family: 'Inter', sans-serif;
  background-color: transparent;
  color: #ffffff;
}

.db-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.db-hero {
  text-align: center;
}

.db-hero h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

section {
  padding-top: 60px;
}

h2 {
  font-size: 36px;
  margin-bottom: 30px;
  color: #ffffff;
}

.db-card {
  background-color: #1a1a1a;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
}

.db-pricing-tiers {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.db-pricing-card {
  background-color: #1a1a1a;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  position: relative;
}

.db-recommended-badge, .db-current-plan-badge {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
}

.db-recommended-badge {
  background-color: #FFD700;
  color: #000000;
}

.db-current-plan-badge {
  background-color: #4299e1;
  color: #ffffff;
}

.db-pricing-card h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.db-price {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 15px;
}

.db-features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
}

.db-features li {
  margin-bottom: 10px;
}

.db-btn {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.db-btn-primary {
  background-color: #3498db;
  color: #ffffff;
}

.db-btn-secondary {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.db-btn:hover {
  opacity: 0.9;
}

.db-error-message {
  color: #e53e3e;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.db-video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.db-video-item {
  background-color: #1a1a1a;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.db-video-item video {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.db-video-info {
  padding: 15px;
}

.db-loading, .db-no-videos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  background-color: #1a1a1a;
  border-radius: 8px;
}

.db-loading svg, .db-no-videos svg {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.db-animate-spin {
  animation: db-spin 1s linear infinite;
}

@keyframes db-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.db-mt-4 {
  margin-top: 1rem;
}

.db-subscription-details {
  background-color: transparent;
}

.db-upgrade-plan {
  background-color: transparent;
}

.db-your-videos {
  background-color: transparent;
}

.db-video-info p {
  margin: 5px 0;
  font-size: 14px;
}

.db-no-videos p {
  margin: 10px 0;
  text-align: center;
}

@media (max-width: 768px) {
  .db-hero h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 28px;
  }

  .db-pricing-tiers {
    grid-template-columns: 1fr;
  }

  .db-video-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .db-hero h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  .db-card, .db-pricing-card {
    padding: 20px;
  }

  .db-btn {
    width: 100%;
  }
}

.db-pricing-tiers .db-container {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.db-pricing-card {
  background-color: #111111;
  border-radius: 8px;
  padding: 40px;
  width: calc(33.333% - 20px);
  text-align: left;
  transition: transform 0.3s ease;
}

.db-pricing-card.db-recommended {
  background-color: #ffffff;
  color: #000000;
}

.db-price {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

.db-free {
  font-size: 24px;
}

.db-currency {
  font-size: 20px;
  vertical-align: top;
  margin-right: 2px;
}

.db-period {
  font-size: 16px;
  font-weight: normal;
  margin-left: 5px;
}

.db-description {
  font-size: 14px;
  margin-bottom: 20px;
  color: #999999;
}

.db-recommended .db-description {
  color: #666666;
}

.db-btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  margin-bottom: 20px;
}

.db-btn-primary {
  background-color: #000000;
  color: #ffffff;
}

.db-btn-secondary {
  background-color: #ffffff;
  color: #000000;
}

.db-features h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.db-features ul {
  list-style-type: none;
  padding: 0;
}

.db-features li {
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.db-features li::before {
  content: "✓";
  position: absolute;
  left: 0;
}

@media (max-width: 1024px) {
  .db-pricing-tiers .db-container {
    flex-wrap: wrap;
  }

  .db-pricing-card {
    width: calc(50% - 15px);
  }
}

@media (max-width: 768px) {
  .db-pricing-card {
    width: 100%;
  }
}

.db-button-group {
  display: flex;
  gap: 10px;
}

.db-button-group .db-btn {
  flex: 1;
}