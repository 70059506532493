.not-found-container {
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    background: transparent;
    color: #ffffff;
    padding: 0 20px;
}

.not-found-title {
    font-size: 64px;
    font-weight: 700;
    margin-bottom: 20px;
}

.not-found-message {
    font-size: 20px;
    margin-bottom: 40px;
    color: #a7a7a7;
}

.not-found-home-link {
    display: inline-block;
    padding: 12px 24px;
    background-color: #ffffff;
    color: #04080C;
    text-decoration: none;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.not-found-home-link:hover {
    background-color: rgba(255, 255, 255, 0.8);
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .not-found-title {
        font-size: 48px;
    }

    .not-found-message {
        font-size: 18px;
    }
}