.error-boundary {
    font-family: 'Inter', sans-serif;
    background-color: #1a1a1a;
    color: #ffffff;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .error-content {
    background-color: #2a2a2a;
    border-radius: 8px;
    padding: 40px;
    max-width: 600px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .error-content h1 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #ff6b6b;
  }
  
  .error-content p {
    font-size: 16px;
    margin-bottom: 30px;
    color: #cccccc;
  }
  
  .error-content details {
    margin-bottom: 30px;
    text-align: left;
  }
  
  .error-content summary {
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
    color: #4ecdc4;
  }
  
  .error-content pre {
    background-color: #1a1a1a;
    padding: 15px;
    border-radius: 4px;
    overflow-x: auto;
    font-size: 14px;
    color: #f0f0f0;
  }
  
  .error-content button {
    background-color: #4ecdc4;
    color: #1a1a1a;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .error-content button:hover {
    background-color: #45b7aa;
  }
  
  @media (max-width: 768px) {
    .error-content {
      padding: 30px;
    }
  
    .error-content h1 {
      font-size: 24px;
    }
  
    .error-content p {
      font-size: 14px;
    }
  
    .error-content pre {
      font-size: 12px;
    }
  
    .error-content button {
      font-size: 14px;
    }
  }