.resources-page {
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  
  .resource-grid {
    padding: 80px 0;
  }
  
  .filters {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  
  .filter-btn {
    background: none;
    border: none;
    font-size: 1rem;
    margin: 0 10px;
    padding: 5px 10px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .filter-btn:hover,
  .filter-btn.active {
    color: #4f46e5;
  }
  
  .resources {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
  }
  
  .resource-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
  }
  
  .resource-card:hover {
    transform: translateY(-5px);
  }
  
  .resource-card h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #1a202c;
  }
  
  .resource-card p {
    font-size: 1rem;
    color: #4a5568;
    margin-bottom: 20px;
    flex-grow: 1;
  }
  
  .resource-type {
    font-size: 0.9rem;
    color: #718096;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  
  .resource-card .btn {
    align-self: flex-start;
  }
  
  .resource-card.tutorial {
    border-top: 4px solid #38a169;
  }
  
  .resource-card.blog {
    border-top: 4px solid #4299e1;
  }
  
  .resource-card.documentation {
    border-top: 4px solid #ed8936;
  }
  
  .newsletter {
    background-color: #f7fafc;
    padding: 80px 0;
    text-align: center;
  }
  
  .newsletter h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .newsletter p {
    font-size: 1.1rem;
    max-width: 600px;
    margin: 0 auto 30px;
    color: #4a5568;
  }
  
  .newsletter-form {
    display: flex;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .newsletter-form input {
    flex-grow: 1;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px 0 0 4px;
  }
  
  .newsletter-form .btn {
    border-radius: 0 4px 4px 0;
  }
  
  .btn {
    display: inline-block;
    padding: 12px 24px;
    background-color: #4f46e5;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    border: none;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #4338ca;
  }
  
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 2.5rem;
    }
  
    .hero p {
      font-size: 1rem;
    }
  
    .resources {
      grid-template-columns: 1fr;
    }
  
    .newsletter-form {
      flex-direction: column;
    }
  
    .newsletter-form input,
    .newsletter-form .btn {
      width: 100%;
      border-radius: 4px;
      margin-bottom: 10px;
    }
  }
  