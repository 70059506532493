.vc-video-creator {
  font-family: 'Inter', sans-serif;
  background-color: transparent;
  color: #ffffff;
  padding: 80px 0;
}

.vc-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.vc-title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

.vc-error-message {
  background-color: rgba(239, 68, 68, 0.2);
  border-left: 4px solid #ef4444;
  color: #fecaca;
  padding: 16px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.vc-icon {
  margin-right: 12px;
}

.vc-subscription-details {
  background-color: #111111;
  border-radius: 8px;
  padding: 40px;
  margin-bottom: 40px;
}

.vc-subtitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.vc-highlight {
  font-weight: 600;
  color: #60a5fa;
}

.vc-upgrade-link {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #ffffff;
  color: #000000;
  text-decoration: none;
  margin-top: 20px;
}

.vc-upgrade-link:hover {
  background-color: #e5e5e5;
}

.vc-file-input-section {
  margin-bottom: 40px;
}

.vc-input-label {
  display: block;
  font-weight: bold;
  margin-bottom: 12px;
  color: #ffffff;
  font-size: 16px;
}

.vc-file-input-wrapper {
  position: relative;
}

.vc-file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.vc-file-input-label {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #ffffff;
  color: #000000;
}

.vc-file-input-label:hover {
  background-color: #e5e5e5;
}

.vc-audio-file-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.vc-audio-file-item {
  background-color: #111111;
  border-radius: 8px;
  padding: 40px;
  transition: transform 0.3s ease;
}

.vc-audio-file-item:hover {
  transform: translateY(-5px);
}

.vc-audio-file-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.vc-audio-file-name {
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
}

.vc-remove-button {
  color: #ef4444;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.vc-remove-button:hover {
  color: #f87171;
}

.vc-status-progress {
  height: 4px;
  background-color: #4b5563;
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 12px;
}

.vc-status-bar {
  height: 100%;
  background-color: #3b82f6;
  transition: width 0.3s ease;
}

.vc-status-text {
  font-size: 14px;
  color: #9ca3af;
  margin-bottom: 20px;
}

.vc-status-success {
  color: #10b981;
}

.vc-status-error {
  color: #ef4444;
}

.vc-generate-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #ffffff;
  color: #000000;
  border: none;
  width: 100%;
}

.vc-generate-button:hover:not(.vc-disabled) {
  background-color: #e5e5e5;
}

.vc-generate-button.vc-disabled {
  background-color: #4b5563;
  cursor: not-allowed;
  opacity: 0.7;
}

.vc-spinning {
  animation: vc-spin 1s linear infinite;
}

@keyframes vc-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.vc-upgrade-message {
  margin-top: 12px;
  text-align: center;
  font-size: 14px;
  color: #9ca3af;
}

.vc-generated-video {
  margin-top: 20px;
}

.vc-video {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
}

.vc-download-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #10b981;
  color: #ffffff;
  text-decoration: none;
  width: 100%;
}

.vc-download-button:hover {
  background-color: #059669;
}

.vc-sign-in-message {
  text-align: center;
  padding: 40px;
  background-color: #111111;
  border-radius: 8px;
}

.vc-sign-in-message p {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .vc-title {
    font-size: 36px;
  }

  .vc-audio-file-list {
    grid-template-columns: 1fr;
  }
}

.vc-pricing-tiers {
  padding: 0 0 80px;
}

.vc-pricing-card {
  background-color: #111111;
  border-radius: 8px;
  padding: 40px;
  text-align: left;
  transition: transform 0.3s ease;
  max-width: 400px;
  margin: 0 auto;
}

.vc-pricing-card h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.vc-price {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

.vc-currency {
  font-size: 20px;
  vertical-align: top;
  margin-right: 2px;
}

.vc-amount {
  font-size: 32px;
}

.vc-period {
  font-size: 16px;
  font-weight: normal;
  margin-left: 5px;
}

.vc-description {
  font-size: 14px;
  margin-bottom: 20px;
  color: #999999;
}

.vc-btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  margin-bottom: 20px;
  width: 100%;
}

.vc-btn-primary {
  background-color: #ffffff;
  color: #000000;
}

.vc-btn-primary:hover {
  background-color: #e5e5e5;
}

.vc-features h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.vc-features ul {
  list-style-type: none;
  padding: 0;
}

.vc-features li {
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.vc-features li::before {
  content: "✓";
  position: absolute;
  left: 0;
}