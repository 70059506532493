.acg-container {
    padding: 100px 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .acg-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .acg-persona-selection {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .acg-generate-button {
    margin-left: 16px;
  }
  
  .acg-loading-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
  }
  
  .acg-spinner {
    margin-bottom: 16px;
  }
  
  .acg-status-message {
    font-size: 16px;
    color: #666;
  }
  
  .acg-results {
    margin-top: 32px;
  }
  
  .acg-section-title {
    font-size: 20px;
    font-weight: semibold;
    margin-bottom: 16px;
  }
  
  .acg-card {
    margin-bottom: 16px;
  }
  
  .acg-card p {
    margin-bottom: 8px;
  }