.ca-container {
    font-family: 'Inter', sans-serif;
    background-color: transparent;
    color: #ffffff;
    padding: 40px;
    min-height: 100vh;
  }
  
  .ca-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  
  .ca-upload-section {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
  }
  
  .ca-upload-button,
  .ca-analyze-button {
    height: 40px;
    border-radius: 4px;
    font-weight: 500;
  }
  
  .ca-upload-button {
    background-color: #333333;
    border-color: #444444;
    color: #ffffff;
  }
  
  .ca-upload-button:hover {
    background-color: #444444;
    border-color: #555555;
  }
  
  .ca-analyze-button {
    background-color: #1890ff;
    border-color: #1890ff;
  }
  
  .ca-analyze-button:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
  
  .ca-spinner {
    margin-top: 24px;
  }
  
  .ca-results {
    margin-top: 40px;
  }
  
  .ca-section-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
  }
  
  .ca-subsection-title {
    font-size: 20px;
    font-weight: 500;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  
  .ca-list {
    list-style-type: disc;
    padding-left: 24px;
    margin-bottom: 24px;
  }
  
  .ca-list li {
    margin-bottom: 8px;
  }
  
  .ca-table {
    margin-bottom: 32px;
  }
  
  /* Overriding Ant Design styles for dark theme */
  .ca-container .ant-table {
    background-color: #1f1f1f;
    color: #ffffff;
  }
  
  .ca-container .ant-table-thead > tr > th {
    background-color: #333333;
    color: #ffffff;
    border-bottom: 1px solid #444444;
  }
  
  .ca-container .ant-table-tbody > tr > td {
    border-bottom: 1px solid #444444;
  }
  
  .ca-container .ant-table-tbody > tr:hover > td {
    background-color: #2a2a2a;
    color: #000000; /* Change text color on hover for better readability */
  }
  
  .ca-container .ant-spin-dot-item {
    background-color: #1890ff;
  }
  
  .ca-container .ant-message-notice-content {
    background-color: #333333;
    color: #ffffff;
  }
  
  .ca-container .ant-btn-primary[disabled],
  .ca-container .ant-btn-primary[disabled]:hover,
  .ca-container .ant-btn-primary[disabled]:focus,
  .ca-container .ant-btn-primary[disabled]:active {
    background-color: #555555;
    border-color: #555555;
    color: #999999;
  }
  
  .ca-status-message {
    margin-top: 16px;
    font-style: italic;
    color: #bfbfbf;
  }
  
  .ca-loading-section {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }
  
  .ca-loading-section .ca-spinner {
    margin-right: 16px;
  }
  
  .ca-persona-selection {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .ca-save-button {
    margin-top: 24px;
  }