.pp-pricing-page {
  font-family: 'Inter', sans-serif;
  background-color: #000000;
  color: #ffffff;
}

.pp-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.pp-hero {
  padding: 80px 0;
  text-align: center;
}

.pp-hero h1 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
}

.pp-pricing-tiers {
  padding: 0 0 80px;
}

.pp-pricing-tiers .pp-container {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.pp-pricing-card {
  background-color: #111111;
  border-radius: 8px;
  padding: 40px;
  width: calc(33.333% - 20px);
  text-align: left;
  transition: transform 0.3s ease;
}

.pp-pricing-card.pp-recommended {
  background-color: #ffffff;
  color: #000000;
}

.pp-pricing-card.pp-recommended h2,
.pp-pricing-card.pp-recommended .pp-price,
.pp-pricing-card.pp-recommended .pp-features {
  color: #000000;
}

.pp-pricing-card h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.pp-price {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pp-free {
  font-size: 24px;
}

.pp-currency {
  font-size: 20px;
  vertical-align: top;
  margin-right: 2px;
}

.pp-period {
  font-size: 16px;
  font-weight: normal;
  margin-left: 5px;
}

.pp-description {
  font-size: 14px;
  margin-bottom: 20px;
  color: #999999;
}

.pp-recommended .pp-description {
  color: #666666;
}

.pp-btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  margin-bottom: 20px;
}

.pp-btn-primary {
  background-color: #000000;
  color: #ffffff;
}

.pp-btn-secondary {
  background-color: #ffffff;
  color: #000000;
}

.pp-features h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.pp-features ul {
  list-style-type: none;
  padding: 0;
}

.pp-features li {
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.pp-features li::before {
  content: "✓";
  position: absolute;
  left: 0;
}

.pp-faq {
  padding: 80px 0;
  background-color: #111111;
}

.pp-faq h2 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 40px;
}

.pp-faq-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.pp-faq-item h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.pp-faq-item p {
  font-size: 14px;
  color: #999999;
}

@media (max-width: 1024px) {
  .pp-pricing-tiers .pp-container {
    flex-wrap: wrap;
  }

  .pp-pricing-card {
    width: calc(50% - 15px);
  }
}

@media (max-width: 768px) {
  .pp-hero h1 {
    font-size: 36px;
  }

  .pp-pricing-card {
    width: 100%;
  }

  .pp-faq-grid {
    grid-template-columns: 1fr;
  }
}

.pp-enterprise-cta {
  text-align: center;
  font-size: 16px;
  margin-top: 40px;
  color: #ffffff;
}
