.company-page {
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
 
  .mission, .values, .team, .contact {
    padding: 80px 0;
  }
  
  .mission h2, .values h2, .team h2, .contact h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .mission p {
    font-size: 1.2rem;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .values-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    margin-top: 50px;
  }
  
  .value-item {
    background-color: transparent;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .value-item:hover {
    transform: translateY(-5px);
  }
  
  .value-item h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #ffffff;
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
    margin-top: 50px;
  }
  
  .team-member {
    text-align: center;
  }
  
  .team-member img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .team-member h3 {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .team-member p {
    color: #6b7280;
  }
  
  .contact {
    background-color: transparent;
    text-align: center;
  }
  
  .contact p {
    max-width: 600px;
    margin: 0 auto 30px;
    font-size: 1.1rem;
  }
  
  .cp-btn {
    display: inline-block;
    padding: 12px 24px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    text-decoration: none;
    border-radius: 4px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #000000;
  }
  
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 2.5rem;
    }
  
    .hero p {
      font-size: 1.2rem;
    }
  
    .mission h2, .values h2, .team h2, .contact h2 {
      font-size: 2rem;
    }
  
    .values-grid, .team-grid {
      grid-template-columns: 1fr;
    }
  }
  