.ft-features {
    font-family: 'Inter', sans-serif;
    background-color: transparent;
    color: #ffffff;
    padding: 80px 0;
  }
  
  .ft-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .ft-title {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .ft-subtitle {
    font-size: 18px;
    color: #999999;
    text-align: center;
    max-width: 600px;
    margin: 0 auto 60px;
  }
  
  .ft-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
  
  .ft-feature {
    text-align: left;
  }
  
  .ft-icon-wrapper {
    background-color: #ffffff;
    color: #111111;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .ft-feature-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .ft-feature-description {
    font-size: 16px;
    color: #999999;
    line-height: 1.5;
  }
  
  @media (max-width: 1024px) {
    .ft-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .ft-grid {
      grid-template-columns: 1fr;
    }
  
    .ft-title {
      font-size: 36px;
    }
  
    .ft-subtitle {
      font-size: 16px;
    }
  }