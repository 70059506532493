@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.beta-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  background-color: #ffd700;
  color: #333;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  transition: transform 0.3s ease-in-out;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  transition: top 0.3s ease-in-out, background-color 0.3s ease;
}

/* Adjust the top position of the navigation when the beta banner is visible */
.beta-banner + .navigation {
  top: 40px; /* Adjust this value based on the height of your beta banner */
}

/* You can remove or comment out the .scrolled class styles for now */

.nav-container {
  max-width: 100%;
  padding: 0 44px; /* Keep this padding consistent */
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px; /* Initial height */
  transition: height 0.3s ease-in-out;
}

/* Only reduce height when scrolled */
.navigation.scrolled .nav-container {
  height: 60px; /* Reduced height when scrolled */
}

.nav-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.nav-logo-image {
  height: 30px;
  width: auto;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  margin: 0 16px;
  transition: color 0.2s ease-in-out;
}

.nav-link:hover,
.nav-link.active {
  color: #ffffff;
  opacity: 0.7;
}

.nav-actions {
  display: flex;
  align-items: center;
}

.nav-discord {
  color: #ffffff;
  margin-right: 24px;
  transition: opacity 0.2s ease-in-out;
}

.nav-discord:hover {
  opacity: 0.7;
}

.nav-button {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 20px;
  padding: 8px 16px;
  margin-left: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-button-primary {
  background-color: #ffffff;
  color: #04080C;
  border: none;
  display: inline-flex;
  align-items: center;
}

.nav-button-primary:hover {
  background-color: rgba(255, 255, 255, 0.8);
  transform: translateY(-1px);
}

/* Responsive design */
@media (max-width: 1024px) {
  .navigation {
    padding: 0 20px;
  }

  .nav-link {
    font-size: 14px;
    margin: 0 12px;
  }

  .nav-button {
    padding: 6px 12px;
  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .nav-actions {
    flex-grow: 1;
    justify-content: flex-end;
  }

  .nav-container {
    padding: 0 10px; /* Reduce padding on smaller screens */
  }
  
  body {
    padding-top: 100px; /* Adjust for smaller screens if needed */
  }
}

/* Add this new class */
.scrolled .navigation {
  top: 0;
}

/* You may need to adjust the content below the navigation */
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden; /* Prevent body scrolling */
}

.scrollable-content {
  height: 100vh; /* Full viewport height */
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 40px; /* Initial padding to account for beta banner */
  transition: padding-top 0.3s ease-in-out;
}

.scrolled .scrollable-content {
  padding-top: 0; /* Remove padding when scrolled (beta banner hidden) */
}

/* When scrolled, always keep the navigation at the top */
.navigation.scrolled {
  top: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Add background color when scrolled */
}
