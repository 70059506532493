.products-page {
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  
  .products {
    padding: 80px 0;
  }
  
  .product-card {
    display: flex;
    margin-bottom: 60px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .product-card:nth-child(even) {
    flex-direction: row-reverse;
  }
  
  .product-image {
    flex: 1;
    min-width: 300px;
  }
  
  .product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .product-info {
    flex: 2;
    padding: 40px;
  }
  
  .product-info h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #1a202c;
  }
  
  .product-info p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    color: #4a5568;
  }
  
  .feature-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;
  }
  
  .feature-list li {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #2d3748;
    position: relative;
    padding-left: 25px;
  }
  
  .feature-list li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #48bb78;
    font-weight: bold;
  }
  
  .cta {
    background: transparent;
    padding: 80px 0;
    text-align: center;
  }
  
  .cta h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .cta p {
    font-size: 1.2rem;
    max-width: 600px;
    margin: 0 auto 30px;
    color: #4a5568;
  }
  
  .btn {
    display: inline-block;
    padding: 12px 24px;
    background-color: #4f46e5;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    border: none;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #4338ca;
  }
  
  .btn-large {
    font-size: 1.2rem;
    padding: 16px 32px;
  }
  
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 2.5rem;
    }
  
    .hero p {
      font-size: 1rem;
    }
  
    .product-card,
    .product-card:nth-child(even) {
      flex-direction: column;
    }
  
    .product-image {
      min-width: 100%;
    }
  
    .product-info {
      padding: 20px;
    }
  
    .product-info h2 {
      font-size: 1.8rem;
    }
  
    .cta h2 {
      font-size: 2rem;
    }
  
    .cta p {
      font-size: 1rem;
    }
  }
  