@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap');

.pricing-page {
    font-family: 'Exo 2', sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  
  .pricing-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
  }
  
  .pricing-toggle span {
    font-size: 1rem;
    margin: 0 10px;
    color: #4a5568;
  }
  
  .pricing-toggle span.active {
    color: #000000;
    font-weight: bold;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #000000;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .pricing-tiers {
    padding: 60px 0;
  }
  
  .pricing-tiers .container {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .pricing-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 200px;
    text-align: center;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .pricing-card:hover {
    transform: translateY(-10px);
  }
  
  .pricing-card.recommended {
    border: 2px solid #5b5b5b;
  }
  
  .recommended-badge, .current-plan-badge {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000000;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: bold;
  }
  
  .current-plan-badge {
    background-color: #48bb78;
  }
  
  .pricing-card h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #1a202c;
  }
  
  .price {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2d3748;
    margin-bottom: 10px;
  }
  
  .price .currency {
    font-size: 1.5rem;
    vertical-align: super;
  }
  
  .price .period {
    font-size: 1rem;
    color: #718096;
  }
  
  .yearly-price {
    font-size: 0.9rem;
    color: #718096;
    margin-bottom: 20px;
  }
  
  
  
  .features li {
    margin-bottom: 10px;
    color: #4a5568;
    position: relative;
    padding-left: 25px;
  }
  
  .features li::before {
    content: '✓';
    position: absolute;

  }
  .features li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #48bb78;
    font-weight: bold;
  }
  
  .btn {
    display: inline-block;
    padding: 12px 24px;
    background-color: #000000;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    border: none;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #2c2c2c;
  }
  
  .btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .btn-secondary {
    background-color: #718096;
  }
  
  .btn-secondary:hover {
    background-color: #4a5568;
  }
  
  .current-plan-label {
    background-color: #48bb78;
    color: white;
    padding: 12px 24px;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .subscription-actions {
    text-align: center;
    margin-top: 40px;
  }
  
  .upgrade-button, .cancel-button {
    margin: 0 10px;
  }
  
  .card-form {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
  }
  
  .submit-button {
    margin-top: 20px;
    width: 100%;
  }
  
  .error-message {
    color: #e53e3e;
    margin-top: 10px;
  }
  
  .faq {
    padding: 80px 0;
   
  }
  
  .faq h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 40px;
    color: #1a202c;
  }
  
  .faq-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
  
  .faq-item h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #2d3748;
  }
  
  .faq-item p {
    color: #4a5568;
  }
  
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 2.5rem;
    }
  
    .hero p {
      font-size: 1rem;
    }
  
    .faq-grid {
      grid-template-columns: 1fr;
    }
  }